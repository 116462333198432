<template>
  <div class="h5-solution-info">
    <div class="h5-solution-info-banner" :class="{'active':isMask}">
      <v-img v-if="solutionInfo.h5Banner" :attachment-id="solutionInfo.h5Banner" :default-img="require('@/assets/img/kctp.png')" />
      <div>
        <p>{{solutionInfo.name}}</p>
        <span>{{solutionInfo.solSlogan}}</span>
        <van-button size="small" class="btn-red" @click="consult">国产替代咨询</van-button>
      </div>
    </div>
    <van-tabs
      v-model="active"
      scrollspy
      sticky
      color="#EA0B06"
      line-width="16px"
    >
      <van-tab name="用户痛点" title="用户痛点">
        <div class="h5-solution-info-td">
          <p>用户痛点</p>
          <div>
            <div v-for="(item,index) in solutionInfo.datSolutionPainpoints" :key="item.id">
              <p>
                <img :src="numImg[index].img" /><span>{{item.name}}</span>
              </p>
              <div>{{item.painpointDiscription}}</div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab name="方案介绍" title="方案介绍">
        <div class="h5-solution-info-desc-box">
          <div class="h5-solution-info-desc">
            <p>方案介绍</p>
            <div>
              <v-img :attachment-id="solutionInfo.solutionImg" :default-img="require('@/assets/img/kctp.png')" />
              <p>{{solutionInfo.solutionDiscription}}</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="方案架构" v-if="solutionInfo.datSolutionFrameWorks"> <frame :val="solutionInfo.datSolutionFrameWorks"/> </van-tab>
      <van-tab title="方案产品" v-if="productData && productData != ''"> <product :val="productData" :id="productId"/> </van-tab>
      <van-tab title="提供商" v-if="solutionInfo.datSolutionIsvs != ''">
        <div class="h5-solution-info-provider">
          <p>提供商</p>
          <div>
            <van-swipe class="my-swipe" indicator-color="#EA0B06">
              <van-swipe-item v-for="i in solutionInfo.datSolutionIsvs" :key="i.id">
                <div class="h5-solution-info-provider-list">
                  <div>
                    <v-img :attachment-id="i.attachmentList?i.attachmentList[0].id:''" :default-img="require('@/assets/img/gyslogo.png')" />
                    <p>{{i.isvName}}</p>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </van-tab>
      <van-tab title="方案优势">
        <div class="h5-home-solution-advantage">
          <p>方案优势</p>
          <div>
            <van-swipe class="my-swipe" indicator-color="#EA0B06">
              <van-swipe-item v-for="i in solutionInfo.datSolutionAdvs" :key="i.id">
                <div class="h5-home-solution-advantage-list">
                  <v-img :attachment-id="i.attmIds" :default-img="require('@/assets/img/gyslogo.png')" />
                  <div>
                    <p>{{i.name}}</p>
                    <span>{{i.advDiscription}}</span>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </van-tab>
      <van-tab title="应用价值">
        <div class="h5-home-solution-value">
          <p>应用价值</p>
          <div>
            <van-swipe class="my-swipe" indicator-color="#EA0B06">
              <van-swipe-item v-for="i in solutionInfo.datSolutionValues" :key="i.id">
                <div class="h5-home-solution-value-list">
                  <v-img :attachment-id="i.attmIds" :default-img="require('@/assets/img/kctp.png')" />
                  <p>{{i.name}}</p>
                  <span>{{i.valueDiscription}}</span>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </van-tab>
      <van-tab title="应用案例">
        <div class="h5-home-solution-case">
          <p>应用案例</p>
          <div>
            <van-swipe class="my-swipe" indicator-color="#EA0B06">
              <van-swipe-item v-for="i in solutionInfo.datSolutionCasess" :key="i.id">
                <div class="h5-home-solution-case-list">
                  <v-img :attachment-id="i.attmIds" :default-img="require('@/assets/img/kctp.png')" />
                  <p>{{i.name}}</p>
                  <span>{{i.caseDiscription}}</span>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { getSolutionInfo } from "@/api/solution";
import frame from "./components/frame.vue";
import product from "./components/product.vue";
import VImg from "@/components/VImg";
import share from '../components/share.vue'
export default {
  components: {
    frame,
    product,
    VImg,
    share
  },
  computed: {
    id: function () {
      return this.$route.query.id;
    },
  },
  data() {
    return {
      numImg:[{
        img:require('@/assets/img/1.png')
      },{
        img:require('@/assets/img/2.png')
      },{
        img:require('@/assets/img/3.png')
      },{
        img:require('@/assets/img/4.png')
      },{
        img:require('@/assets/img/5.png')
      },{
        img:require('@/assets/img/6.png')
      },{
        img:require('@/assets/img/7.png')
      },{
        img:require('@/assets/img/8.png')
      },{
        img:require('@/assets/img/9.png')
      }],
      active: 0,
      solutionInfo:'',
      productData:'',
      productId:'',
      isMask:false,
      shareInfo: {
          title: '',
          desc: '',
          img: ''
      }
    };
  },
  mounted(){
    document.title = '寻｜解决方案'
    this.loadGetSolutionInfo()
  },
  methods:{
    consult(){
      localStorage.removeItem('orderVal')
      this.$router.push('/demandMove')
    },
    // 方案详情
    loadGetSolutionInfo(){
      getSolutionInfo(this.id).then((res)=>{
        this.solutionInfo = res
        this.shareInfo.title = res.name
        this.shareInfo.desc = res.solutionDiscription
        this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.h5Banner}`
        if(res.datSolutionFrameWorks){
          let data = []
          res.datSolutionFrameWorks.forEach(element => {
            if(element.datProductList){
              data = [...data,...element.datProductList]
            }
          });
          this.productData = data
          if(data != ''){
            this.productId = data[0].id
          }
        }
        let createDate = new Date(res.createTime);
        let upDate = new Date('2022-07-22 00:00:00');
        createDate = createDate.getTime();
        upDate = upDate.getTime();
        if(createDate > upDate){
          this.isMask = true
        }
        console.log(this.isMask,'this.isMask')
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.h5-solution-info {
  min-height: 100%;
  background: #f6f7f9;
  .active{
    &::after{
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(90deg, #EEF3F9 0%, rgba(238,243,249,0.5000) 100%);
    }
  }
  .h5-solution-info-banner {
    height: 246px;
    color: #252b3a;
    box-sizing: border-box;
    position: relative;
    >img{
      width: 100%;
      height: 100%;
      display: block;
    }
    >div{
      padding: 40px 16px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      > p {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 6px;
      }
      > span {
        font-size: 12px;
        display: block;
        line-height: 17px;
        margin-bottom: 46px;
      }
      >bottom{
        display: block;
      }
    }
  }
  .h5-solution-info-td {
    padding: 36px 16px 0;
    background: url(../../assets/img/tdbg.png) center top no-repeat;
    > p {
      font-size: 18px;
      line-height: 16px;
      color: #333333;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid #EA0B06;
      margin-bottom: 22px;
    }
    > div {
      background: #fff;
      > div {
        padding: 24px 16px;
        border-bottom: 1px solid #f2f2f2;
        &:last-child {
          border-bottom: 0;
        }
        > p {
          font-size: 14px;
          position: relative;
          line-height: 20px;
          color: #333333;
          font-weight: bold;
          margin-bottom: 16px;
          > img {
            width: 40px;
            height: 40px;
            display: block;
            position: absolute;
            left: -16px;
            bottom: -10px;
          }
          > span {
            position: relative;
            z-index: 2;
          }
        }
        > div {
          font-size: 12px;
          color: #666;
          line-height: 18px;
          text-align: justify;
        }
      }
    }
  }
  .h5-solution-info-desc-box {
    padding: 30px 16px;
    .h5-solution-info-desc {
      > p {
        font-size: 18px;
        line-height: 16px;
        color: #333333;
        font-weight: bold;
        padding-left: 10px;
        border-left: 3px solid #EA0B06;
        margin-bottom: 22px;
      }
      > div {
        padding: 16px;
        background: #fff;
        > img {
          width: 100%;
          height: 176px;
          display: block;
        }
        > p {
          font-size: 12px;
          line-height: 18px;
          text-align: justify;
          margin-top: 16px;
        }
      }
    }
  }
  .h5-solution-info-provider {
    padding: 30px 16px 0;
    > p {
      font-size: 18px;
      line-height: 16px;
      color: #333333;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid #EA0B06;
      margin-bottom: 22px;
    }
    .h5-solution-info-provider-list {
      display: flex;
      flex-wrap: wrap;
      > div {
        width: 100%;
        height: 126px;
        background: #ffffff;
        border: 1px solid #eaedf2;
        padding: 24px 24px;
        margin: 0 8px 8px 0;
        box-sizing: border-box;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3) {
          margin-bottom: 0;
        }
        > img {
          display: block;
          max-width: 100%;
          max-height: 44px;
          margin: 0 auto;
        }
        > p {
          text-align: center;
          font-size: 14px;
          color: #333;
          line-height: 20px;
          margin-top: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 0;
        }
      }
    }
  }
  .h5-home-solution-advantage {
    padding: 30px 16px;
    > p {
      font-size: 18px;
      line-height: 16px;
      color: #333333;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid #EA0B06;
      margin-bottom: 22px;
    }
    .h5-home-solution-advantage-list {
      background: #fff;
      // margin: 0 24px 24px 0;
      padding: 16px;
      display: flex;
      display: flex;
      align-items: flex-start;
      > img {
        margin-right: 12px;
        width: 32px;
        height: 32px;
      }
      > div {
        flex: 1;
        > p {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 16px;
        }
        > span {
          display: block;
          font-size: 12px;
          color: #8c8c8c;
          line-height: 18px;
        }
      }
    }
  }
  .h5-home-solution-value{
    padding-bottom: 30px;
    padding: 24px 16px 2px;
    background: #fff;
    > p {
      font-size: 18px;
      line-height: 16px;
      color: #333333;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid #EA0B06;
      margin-bottom: 22px;
    }
    .h5-home-solution-value-list{
      >img{
        display: block;
        height: 194px;
        width: 100%;
        margin-bottom: 18px;
      }
      >p{
        font-size: 14px;
        color: #333;
        margin-bottom: 14px;
        line-height: 18px;
      }
      >span{
        display: block;
        font-size: 12px;
        line-height: 18px;
        color: #666;
        text-align: justify;
      }
    }
  }
  .h5-home-solution-case{
    padding-bottom: 30px;
    padding: 24px 16px 0;
    background: #fff;
    > p {
      font-size: 18px;
      line-height: 16px;
      color: #333333;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid #EA0B06;
      margin-bottom: 22px;
    }
    .h5-home-solution-case-list{
      >img{
        display: block;
        height: 194px;
        width: 100%;
        margin-bottom: 18px;
      }
      >p{
        font-size: 14px;
        color: #333;
        margin-bottom: 14px;
        line-height: 18px;
      }
      >span{
        display: block;
        font-size: 12px;
        line-height: 18px;
        color: #666;
        text-align: justify;
      }
    }
  }
}
</style>
<style>
/* .van-swipe__indicators{
  bottom: 6px;
} */
.van-swipe__indicator{
  background: #eee;
  width: 24px;
  height: 2px;
  border-radius: 0;
}
.van-swipe{
  padding-bottom: 10px;
}
.h5-solution-info .van-tabs--line .van-tabs__wrap{
  height: 46px;
}
</style>
