<template>
  <div class="h5-solution-info-frame-box">
    <div class="h5-solution-info-frame">
      <p>方案架构</p>
      <div>
        <div class="frame-a">
          <div v-for="item in frameDataA" :key="item.id">
            <img :src="item.appIcon" />
            <p :class="{ active: item.pdtIds != null }">
              <img
                v-if="item.pdtIds != null"
                src="@/assets/img/jth.png"
                class="red"
              />
              <img v-else src="@/assets/img/jthui.png" class="gray" />
              {{ item.appName }}
            </p>
          </div>
        </div>
        <div class="frame-b">
          <div v-for="item in frameDataB" :key="item.id">
            <img :src="item.appIcon" />
            <p :class="{ active: item.pdtIds != null }">
              <img
                v-if="item.pdtIds != null"
                src="@/assets/img/jth.png"
                class="red"
              />
              <img v-else src="@/assets/img/jthui.png" class="gray" />
              {{ item.appName }}
            </p>
          </div>
        </div>
        <div class="frame-c">
          <div v-for="item in frameDataC" :key="item.id">
            <img :src="item.appIcon" />
            <p :class="{ active: item.pdtIds != null }">
              <img
                v-if="item.pdtIds != null"
                src="@/assets/img/jth.png"
                class="red"
              />
              <img v-else src="@/assets/img/jthui.png" class="gray" />
              {{ item.appName }}
            </p>
          </div>
        </div>
        <div class="frame-d">
          <div v-for="item in frameDataD" :key="item.id">
            <img :src="item.appIcon" />
            <p :class="{ active: item.pdtIds != null }">
              <img
                v-if="item.pdtIds != null"
                src="@/assets/img/jth.png"
                class="red"
              />
              <img v-else src="@/assets/img/jthui.png" class="gray" />
              {{ item.appName }}
            </p>
          </div>
        </div>
        <div class="frame-e">
          <div v-for="item in frameDataE" :key="item.id">
            <img :src="item.appIcon" />
            <p :class="{ active: item.pdtIds != null }">
              <img
                v-if="item.pdtIds != null"
                src="@/assets/img/jth.png"
                class="red"
              />
              <img v-else src="@/assets/img/jthui.png" class="gray" />
              {{ item.appName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
export default {
  components: {
    VImg,
  },
  props: ["val"],
  data() {
    return {
      frameInfoIndex: 0,
      frameDataA: [],
      frameDataB: [],
      frameDataC: [],
      frameDataD: [],
      frameDataE: [],
      devData: [],
      socData: [],
      osData: [],
    };
  },
  mounted() {
    let _this = this;
    this.$nextTick(() => {
      setTimeout(() => {
        _this.getframeData();
      }, 300);
    });
  },
  methods: {
    getframeData() {
      let { val } = this;
      // console.log(val)
      val.forEach((element) => {
        if (element.layerType == 1) {
          this.frameDataA.push(element);
        } else if (element.layerType == 2) {
          this.frameDataB.push(element);
        } else if (element.layerType == 3) {
          this.frameDataC.push(element);
        } else if (element.layerType == 4) {
          this.frameDataD.push(element);
        } else if (element.layerType == 5) {
          this.frameDataE.push(element);
        }
        if (element.pdtIds != null) {
          element.appIcon = element.appIcon.replace(/gzc/g, "gzc_red");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.frame-a {
  top: 62px;
  left: 180px;
  z-index: 5;
}
.frame-b {
  top: 84px;
  left: 144px;
  z-index: 4;
}
.frame-c {
  top: 110px;
  left: 104px;
  z-index: 3;
}
.frame-d {
  top: 142px;
  left: 50px;
  z-index: 2;
}
.frame-e {
  top: 164px;
  left: 8px;
  z-index: 1;
}
.h5-solution-info-frame-box {
  background: #fff;
  .h5-solution-info-frame {
    padding: 30px 6px;
    > p {
      font-size: 18px;
      line-height: 16px;
      color: #333333;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid #EA0B06;
      margin-bottom: 22px;
      margin-left: 10px;
    }
    > div {
      width: 100%;
      height: 240px;
      background: url(../../../assets/img/gs2.png) no-repeat;
      background-size: 100% 100%;
      position: relative;
      > div {
        display: flex;
        transform: rotate(30deg);
        width: 180px;
        height: 20px;
        position: absolute;
        > div {
          margin-left: 12px;
          transform: rotate(-30deg);
          cursor: pointer;
          position: relative;
          > img {
            width: 20px;
            height: 20px;
            display: block;
          }
          > p {
            display: none;
            position: absolute;
            height: 14px;
            border: 1px solid #666666;
            padding: 0 6px;
            font-size: 10px;
            color: #666;
            line-height: 14px;
            top: 28px;
            right: -4px;
            white-space: nowrap;
            background: #fff;
            z-index: 10;
            > img {
              position: absolute;
              right: 10px;
              top: -6px;
              &.red {
                display: none;
              }
            }
            &.active {
              color: #ea0b06;
              border-color: #ea0b06;
              > img {
                &.red {
                  display: block;
                }
                &.gray {
                  display: none;
                }
              }
            }
          }
          &:hover {
            > p {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
