<template>
  <div class="h5-solution-info-product">
    <p>方案产品</p>
    <div>
      <div v-for="el in val" :key="el.id" :class="{'active':productId == el.id}">
        <p @click="productInfoShow(el.id)">{{el.name}} <van-icon name="arrow-down" /></p>
        <div>
          <div>
            <template v-if="el.datSocList">
              <p :class="{'active':iotType == 'soc'}" @click="iotType = 'soc'" v-for="i in el.datSocList" :key="i.id"><img src="@/assets/img/xpicon.png" />SOC：{{i.name}}</p>
            </template>
            <p :class="{'active':iotType == 'dev'}" @click="iotType = 'dev'" v-if="el.datDevboard"><img src="@/assets/img/kfbicon.png" />开发板：{{el.datDevboard.name}}</p>
            <template v-if="el.datOsList">
              <p :class="{'active':iotType == 'os'}" @click="iotType = 'os'" v-for="i in el.datOsList" :key="i.id"><img src="@/assets/img/osicon.png" />嵌入式OS：{{i.name}}</p>
            </template>
          </div>
          <template v-if="iotType == 'soc'">
            <p><i></i>名称<span v-for="i in el.datSocList" :key="i.id">{{i.name}}</span></p>
            <p><i></i>系列<span v-for="i in el.datSocList" :key="i.id">{{i.setNum || '/'}}</span></p>
            <p><i></i>型号<span v-for="i in el.datSocList" :key="i.id">{{i.modelNum}}</span></p>
          </template>
          <template v-if="iotType == 'dev'">
            <p><i></i>名称<span>{{el.datDevboard.name}}</span></p>
            <p><i></i>型号<span>{{el.datDevboard.modelNum}}</span></p>
            <p><i></i>嵌入式OS<span v-for="i in el.datDevboard.datOs" :key="i.id">{{i.name}}</span></p>
          </template>
          <template v-if="iotType == 'os'">
            <p><i></i>名称<span v-for="i in el.datOsList" :key="i.id">{{i.name}}</span></p>
            <p><i></i>板本<span v-for="i in el.datOsList" :key="i.id">{{i.sysVersion}}</span></p>
            <p><i></i>适配<span v-for="i in el.datOsList" :key="i.id">{{i.socMapping}}</span></p>
          </template>
          <p><i></i>场景<span>{{el.sceneTagName}}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['val','id'],
  data(){
    return{
      iotType:'soc',
      productId:''
    }
  },
  mounted(){
    this.productId = this.id
  },
  methods:{
    productInfoShow(id){
      let {productId} = this
      if(productId != id){
        this.productId = id
      }else{
        this.productId = ''
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.h5-solution-info-product {
  background: #fff;
  padding-top: 22px;
  // margin-bottom: 30px;
  > p {
    font-size: 18px;
    line-height: 16px;
    color: #333333;
    font-weight: bold;
    padding-left: 10px;
    border-left: 3px solid #EA0B06;
    margin-bottom: 22px;
    margin-left: 16px;
  }
  > div {
    > div {
        border-top: 1px solid #eee;
        >p{
            height: 64px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            color: #333333;
            margin-bottom: 0;
            >i{
               transition: all .3s;
            }
        }
        >div{
            display: none;
            background: #F3F7FF;
            padding: 16px;
            >div{
                color: #333;
                width: 100%;
                overflow-y: auto;
                white-space: nowrap;
                padding-bottom: 10px;
                >p{
                    display: inline-block;
                    height: 40px;
                    padding: 0 10px;
                    font-size: 14px;
                    border-radius: 2px;
                    background: #fff;
                    margin-right: 8px;
                    line-height: 40px;
                    margin-bottom: 0;
                    &.active{
                        background: #EA0B06;
                        color: #fff;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    >img{
                        float: left;
                        margin-right: 4px;
                        margin-top: 10px;
                    }
                }
            }
            >p{
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #8c8c8c;
                line-height: 16px;
                margin-top: 6px;
                >i{
                    width: 4px;
                    height: 4px;
                    background: #EA0B06;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                >span{
                    color: #262626;
                    margin-left: 10px;
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        &.active{
            >p{
                color: #EA0B06;
                >i{
                    color: #EA0B06;
                    transform: rotate(180deg);
                }
            }
            >div{
                display: block;
            }
        }
    }
  }
}
</style>
